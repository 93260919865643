/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/

.productCard {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-direction: column !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.placeButton {
  padding-top: 16px !important;
  color: #000 !important;
  font-family: Mint Grotesk Display Black !important;
  font-size: 17px !important;
  font-style: normal !important;
  line-height: normal !important;
}

.placeButton > span {
  text-decoration-line: underline !important;
}

.productName {
  padding-top: 10px !important;
  color: #000 !important;
  font-family: Mint Grotesk Display Light !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: normal !important;
}

/* Mobile */
@media only screen and (max-width: 768px) {
}
