/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/

.flexContainer {
  grid-template-columns: repeat(3, 1fr);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .flexContainer {
    grid-template-columns: repeat(1, 1fr);
    flex-flow: column !important;
  }
}
