/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/

.productCard {
  background-color: #fcfbfb !important;
  height: 550px !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.mainImageContainer {
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  flex-grow: 1;
  padding: 24px;
  max-height: calc(100% - 53px);
}

.mainImageContainer img {
  display: block;
}

.subTextDiv {
  margin: 0 !important;
  padding: 0 !important;
  height: 53px !important;
  width: 100% !important;
  display: inline-flex !important;
  flex-direction: row !important;
  flex-shrink: 0;
  align-content: space-between !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding-left: 17px !important;
  padding-right: 17px !important;
  background: #f5f5f5 !important;
}

.subTextDiv_index {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.bastaActive {
  background: var(--green-one-off) !important;
}

.subTextIcon {
  height: 12px;
  width: 12px;
  max-height: 12px;
  display: flex;
}

.subTextIcon_index {
  height: 11px;
  width: 11px;
  max-height: 11px;
  display: flex;
}

.subText {
  margin-left: 7px !important;
  color: var(--black-one-off) !important;
  font-family: Mint Grotesk Display Black !important;
  font-size: 20px !important;
  font-style: normal !important;
  line-height: normal !important;
}

.subText_index {
  margin-left: 8.1px !important;
  color: var(--black-one-off) !important;
  font-family: Mint Grotesk Display !important;
  text-align: right !important;
  font-size: 12px !important;
  font-style: normal !important;
  line-height: normal !important;
}

.subTextDiv > div {
  display: flex !important;
  align-items: center !important;
}

/* Mobile */
@media only screen and (max-width: 768px) {
}
