/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.scrolling_container {
  display: block;
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 10px;
  z-index: 0;
  position: relative;
}

.scrolling_div {
  width: 100%;
  white-space: nowrap;
  background-color: var(--white-one-off);
}

.scrolling_div_dark_mode {
  background-color: var(--black-one-off) !important;
}

.scrolling_div p {
  margin: 0px;
  color: var(--black-one-off);
  font-family: Graphik Wide;
  font-size: 35px;
  font-style: italic;
  line-height: normal;
  padding-right: 80px;
}

.scrolling_div p > a > span > span,
.scrolling_div p > span > span {
  display: inline-block;
  color: var(--green-one-off);
  font-family: Graphik Wide;
  font-size: 35px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 80px;
  min-width: 650px;
}

.scrolling_timer span {
  display: inline-block;
  width: 270px;
  text-align: center;
}

@media screen and (max-width: 768px){
  .scrolling_container {
    margin-top: 15px !important;
  }
}
