/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.cust_img {
  width: 100%;
  height: 100%;
}

.heading {
  text-align: left !important;
  color: var(--white-one-off) !important;
  font-family: Mint Grotesk Display !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 16px !important; /* 80% */
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
  max-width: 450px !important;
}

.subHeading {
  text-align: left !important;
  color: var(--white-one-off) !important;
  font-family: Mint Grotesk Display Light !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: normal;
  line-height: 16px !important; /* 133.333% */
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 25px !important;
  max-width: 450px !important;
}

.callToAction {
  text-align: left !important;
  color: var(--white-one-off) !important;
  font-family: Graphik Wide !important;
  font-size: 12px !important;
  font-style: normal !important;
  line-height: normal !important;
  text-decoration-line: underline !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 25px !important;
}

.callToAction > span > i strong {
  color: var(--white-one-off);
  font-family: Graphik Wide Semibold Italic !important;
  font-size: 12px;
  line-height: normal;
  text-decoration-line: underline;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .heading {
    /* display: none; */
    color: var(--black-one-off) !important;
    text-align: center !important;
    font-family: Mint Grotesk Display Light !important;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 35px !important; /* 116.667% */
  }

  .subHeading {
    color: var(--black-one-off) !important;
    text-align: center !important;
    font-family: Mint Grotesk Display Light !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 27px !important; /* 135% */
  }

  .callToAction > span {
    color: var(--black-one-off) !important;
    font-family: Graphik Wide !important;
    font-size: 23px !important;
    line-height: normal !important;
    text-decoration-line: underline !important;
  }
  .callToAction > span > i strong {
    color: var(--black-one-off) !important;
    font-family: Graphik Wide Semibold Italic !important;
    font-size: 23px !important;
    line-height: normal !important;
    text-decoration-line: underline !important;
  }
}
